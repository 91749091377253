/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import NewLayout from "src/components/new/Layout"
import Header from "src/components/new/Header"
import Content from "src/components/Content"
import FAQ from "src/components/new/FAQ"

import "./homeTemplate.scss"

const HomeTemplate = ({data: {page: {frontmatter: {
  meta, capabilities, steps, faqs
}}}}) => {

  // doing this inline was not working
  const chunkCapabilities = capabilities => {
    const chunked = []
    let index = 0
    while(index < capabilities.length) {
      chunked.push(capabilities.slice(index, index+5))
      index += 5
    }
    return chunked
  }
  
  return (
    <NewLayout meta={meta}>
      <Header blue={true}>
        <h1>
          <span>Vaporware is a B2B software<br />startup studio.</span>
          <br />
          We partner with founders to build high-growth companies.
        </h1>
      </Header>
      <div className="home-template">
        <div className="home-headline">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h3>What We Do</h3>
                <h2>We validate, build, and sell B2B SaaS products with founders who have domain expertise and vision.</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="home-capabilities">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="capabilities-inner">
                  {chunkCapabilities(capabilities).map((fiveCapabilities, index) => 
                    <ul key={fiveCapabilities[0]}>
                      {fiveCapabilities.map(capability => <li key={capability}>{capability}</li>)}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-results">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h3>Results</h3>
                <div className="row">
                  <div className="col-lg result">
                    <h2>9</h2>
                    <h4>Funded Startups</h4>
                    <p>Thriving startups we've <br />helped scale and fund.</p>
                  </div>
                  <div className="col-lg result">
                    <h2>70</h2>
                    <h4>Ideas Tested</h4>
                    <p>Founders we’ve helped <br />test the market with their idea.</p>
                  </div>
                  <div className="col-lg result">
                    <h2>8.4M</h2>
                    <h4>Funds Raised</h4>
                    <p>Pre-series investments <br />our startups have raised.</p>
                  </div>
                </div>
                <p className="learn"><a href="/startups">See some of the startups we’ve helped.</a></p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="home-process">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h3>How we do it</h3>
                <h2>Building a successful startup takes years and often leaves founders over-diluted. We've developed the playbook and team to eliminate those risks.</h2>
              </div>
            </div>
            {steps.map(step => 
              <div key={step.title} className="row align-items-center">
                <div className="col-md-5">
                  <div className="process-image">
                    <img src={step.image} alt={step.title} />
                  </div>
                </div>
                <div className="col-md-7">
                  <h3>{step.title}</h3>
                  <Content html={step.description} />
                </div>
              </div>
            )}
          </div>
        </div>  

        <div className="home-faq">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h3>FAQ</h3>
                <ul>{faqs.map(faq => <FAQ key={faq.question} content={faq} />)}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </NewLayout>
)}

HomeTemplate.defaultProps = {
  data: {
    page: {
      frontmatter: {
        meta: {},
      }
    },
  }
}

HomeTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.object,
      })
    }),
  })
}

export default HomeTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: {meta: {path: {eq: $path }}}) {
      html
      ...MetaFragment
      frontmatter {
        capabilities
        steps {
          title
          image
          description
        }
        faqs {
          question
          answer
        }
      }
    }
  }
`
