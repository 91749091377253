import React, { useState } from "react"

import Content from "src/components/Content"
import caret from "src/assets/images/faq-caret.svg"
import "./FAQ.scss"

const FAQ = ({ content }) => {

  const [open, setOpen] = useState(false)

  const { question, answer } = content

  return (
    <li className={`faq ${open ? "open" : ""}`}>
      <img src={caret} onClick={() => setOpen(!open)} />
      <div className="question" onClick={() => setOpen(!open)}>
        {question}
      </div>
      <div className="answer">
        <Content html={answer} />
      </div>
    </li>
  )
}

export default FAQ
